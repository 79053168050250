<script setup>
import Layout from "../../layouts/main.vue";
import { onMounted, reactive, ref } from "vue";
import { useRouter } from "vue-router";
import MethodService from "../../service/MethodService";
import toastr from "toastr";
import DataForm from "./data-dich-vu";
import mushroom from "cem-primary-api";
import { VueDraggableNext } from "vue-draggable-next";
import LanguageService from "../../service/LanguageService";
import { langWeb } from "../../stores/lang";
const lang_web = langWeb();
const lang = ref(lang_web.showLang.toLocaleUpperCase())

let loading = ref(true);
const router = useRouter();
// const route = useRoute();

const tableRules = reactive(MethodService.copyObject(DataForm.tableRules));
const dataTables = reactive({ value: [] }); // data bảng

// Lấy danh sách dịch vụ
const getService = async () => {
  let dataFilter = {
    filters: tableRules.filters,
    limit: 9999,
    offset: tableRules.offset,
    sort: tableRules.sort,
  };
  try {
    const serviceApiRes = await mushroom.service.listAsync(dataFilter, {
      cacheAge: 1,
    });
    if (serviceApiRes.result) {
      dataTables.value = serviceApiRes.result;
      tableRules.total = serviceApiRes.meta.total;
      tableRules.page =
        serviceApiRes.meta.offset / serviceApiRes.meta.limit + 1;
      loading.value = false;
    }
  } catch (e) {
    MethodService.showError(e.code);
  }
};

// cập nhật dịch vụ
const updateService = async (listService) => {
  let listPromise = [];
  listService.forEach((service, i) => {
    const serviceData = {
      id: service.id,
      name: service.name.trim(),
      fullname: service.fullname.trim(),
      disabled: service.disabled,
      index: i,
    };
    listPromise.push(mushroom.service.partialUpdateAsync(serviceData));
  });
  try {
    await Promise.all(listPromise).then(() => {
      toastr.success(LanguageService?.[lang.value].toastr_update_success ?? "toastr_update_success");
      router.go(-1);
    });
  } catch (error) {
    MethodService.showError(error.code);
  }
};

const changeIndexService = () => {
  // console.log('event', event);
  // console.log('dataTables.value', dataTables.value);
  updateService(dataTables.value);
};

const cancelChangeIndex = () => {
  router.go(-1);
}

onMounted(() => {
  getService();
});
</script>

<template>
  <Layout>
    <div class="card">
      <div class="card-header">
        <h3>{{ $t("t-config-display-service") }}</h3>
      </div>
      <div class="card-body">
        <div class="main-body" v-loading="loading">
          <VueDraggableNext class="dragArea list-group w-full" :list="dataTables.value">
            <transition-group name="listService">
              <div class="list-group-item bg-gray-300 m-1 p-1 rounded-md text-center" v-for="(el) in dataTables.value"
                :key="el.id">
                <div style="
                display: flex;
                justify-content: space-between;
                align-items: center;
                cursor: pointer;
                padding: 10px;
              ">
                  <span>
                    <i class="bx bx-move label-icon align-middle fs-16 me-2"></i>
                    {{ el.name }}</span>
                </div>
              </div>
            </transition-group>
          </VueDraggableNext>
          <div class="text-center mt-3">
            <button type="button" class="btn btn-soft-secondary btn-border me-2" @click="cancelChangeIndex">
              {{ $t("t-back") }}
            </button>
            <button type="button" class="btn btn-secondary btn-border" @click="changeIndexService">
              {{ $t("t-update") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style lang="scss" scoped>
.listService-move,
/* apply transition to moving elements */
.listService-enter-active,
.listService-leave-active {
  transition: all 0.5s ease;
}
</style>